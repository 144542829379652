$(function () {

    // change class on navbar
    var  navbar = $(".navbar");
    var scrolledClass = "navbar-scrolled";
    var height = $('.navbar').height();

    $(window).scroll(function() {
      // scrolled add class. if not, remove class
      if( $(this).scrollTop() > 0 ) {
        navbar.addClass(scrolledClass);
      } else {
        navbar.removeClass(scrolledClass);
      }
    });

});
